import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLabelList,
  CSectTitle,
  CFadeSlider,
  CFixedImg,
  CSimpleCard,
  LBanquetIncs,
  LVenue,
  LWifi,
  LBanquetContact,
  CSpSliderCard03,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: '中宴会場「芙蓉」',
            sub: '宴会棟 2F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/fuyo/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/fuyo/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: '中宴会場「芙蓉」',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap>
          <h2 className="c_headingLv2">中宴会場「芙蓉」</h2>
          <CLabelList
            data={[
              'パーティー',
              '会議・コンベンション',
              '講演・セミナー',
              'イベント・展示',
              '同窓会',
            ]}
          />
          <CSpSliderCard03
              exClass="u_mb80"
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/fuyo/img_middle_hall.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/fuyo/img_middle_hall02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/fuyo/img_middle_hall03.png',
                    alt: '',
                  },
                },
              ]}
            />
          <p className="c_sectLead u_mb30">
            高級感の漂う中宴会場「芙蓉」は、立食で最大350名を収容。
            <br />
            クラシックとモダンが融合したヨーロッパの邸宅のような会場は、2室に分割可能で、謝恩会や同窓会、学術会議など、多目的にご利用いただけます。
          </p>
          {!isSp ? (
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th colSpan={4}>人数</th>
                  <th rowSpan={2}>広さ（㎡）</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>全スパン</th>
                  <td>180</td>
                  <td>350</td>
                  <td>-</td>
                  <td>-</td>
                  <td>430</td>
                </tr>
                <tr>
                  <th>A</th>
                  <td>120</td>
                  <td>200</td>
                  <td>144</td>
                  <td>230</td>
                  <td>280</td>
                </tr>
                <tr>
                  <th>B</th>
                  <td>60</td>
                  <td>80</td>
                  <td>72</td>
                  <td>120</td>
                  <td>150</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>正餐</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>180</td>
                    <td>430</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>120</td>
                    <td>280</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>60</td>
                    <td>150</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>立食</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>350</td>
                    <td>430</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>200</td>
                    <td>280</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>80</td>
                    <td>150</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>スクール</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>-</td>
                    <td>430</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>144</td>
                    <td>280</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>72</td>
                    <td>50</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>-</td>
                    <td>430</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>230</td>
                    <td>280</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>120</td>
                    <td>150</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <CFixedImg
            exClass="u_mbLarge"
            width={1040}
            img={{
              src: '/assets/images/banquet/fuyo/img_middle_hall04.png',
              alt: '',
            }}
          />
          <CSectTitle
            title={{
              ja: 'レイアウト例',
              en: (
                <>
                  LAYOUT <br className="u_sp" />
                  EXAMPLE
                </>
              ),
            }}
          />
          <CSimpleCard
            data={[
              {
                img: {
                  src: '/assets/images/banquet/fuyo/img_middle_hall05.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    芙蓉A：ブッフェ 200名
                    <br />
                    芙蓉B：ブッフェ 80名
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/fuyo/img_middle_hall06.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    芙蓉A：正餐 120名
                    <br />
                    芙蓉B：正餐 60名
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/fuyo/img_middle_hall07.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    芙蓉A：スクール 144名
                    <br />
                    芙蓉B：スクール 72名
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/fuyo/img_middle_hall08.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    芙蓉A：シアター 230名
                    <br />
                    芙蓉B：シアター 120名
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LBanquetIncs />
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
